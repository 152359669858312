<template>
  <Layout>
    <page-layout page-titles="プラン管理">
      <template #table-content>
        <p-i-m-table :show-add-dialog="showAddDialog" :show-edit-dialog="showEditDialog">
          <template #dialog>
            <c-modal :id="dialogId" :title="dialogTitle" :buttons="dialogButtons">
              <p-i-form
                :form-data.sync="formData"
                :trigger-validate="triggerValidate"
                :is-valid.sync="isValid"
                :view-only="viewOnly"
              />
            </c-modal>
          </template>
        </p-i-m-table>
      </template>
    </page-layout>
  </Layout>
</template>

<script>
import Layout from '@/layouts/main/app'
import PIMTable from '@/components/tables/package-information-management-table'
import PageLayout from '@/components/commons/common-page-layout'
import CModal from '@/components/commons/common-modal'
import PIForm from '@/components/forms/package-information-management-form'
import { mapActions } from 'vuex'
import pick from 'lodash-es/pick'
import { ALERT_TYPE, FORM_MODE } from '@/utils/constants'
import { MESSAGES } from '@/utils/messages'
import { packageInformationService } from '@/services/package-information-service'

export default {
  name: 'PackageInformationManagement',
  components: {
    Layout,
    PIMTable,
    PageLayout,
    CModal,
    PIForm,
  },
  data() {
    return {
      searchInput: '',
      formData: null,
      dialogId: 'pi-dialog',
      dialogTitle: '',
      dialogDeleteId: 'del-dialog',
      triggerValidate: false,
      isValid: false,
      requiredCreateKeys: ['name', 'price', 'count_users'],
      requiredEditKeys: ['id', 'name', 'price', 'count_users'],
      onAddOrEditConfirm: () => {
        return {}
      },
      dialogTitles: {
        ADD: {
          id: 1,
          text: 'プラン追加',
        },
        EDIT: {
          id: 2,
          text: 'プラン情報編集',
        },
        DELETE: {
          id: 3,
          text: '',
        },
      },
      buttonText: '',
      formMode: FORM_MODE.ADD,
    }
  },
  computed: {
    dialogButtons() {
      return [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: this.buttonText,
          variant: 'primary',
          minWidth: 133,
          size: 'md',
          func: this.onAddOrEditConfirm,
        },
      ]
    },
    viewOnly() {
      return this.formMode !== FORM_MODE.ADD
    },
  },
  methods: {
    ...mapActions('alert', ['displayAlert']),
    ...mapActions('commonSettings', ['fetchCommonSetting']),
    showEditDialog(rowData, editSuccessCallback) {
      this.formMode = FORM_MODE.EDIT
      this.dialogTitle = this.dialogTitles.EDIT.text
      this.formData = { ...rowData }
      this.buttonText = '更新'
      this.onAddOrEditConfirm = async () => {
        this.triggerValidate = !this.triggerValidate
        setTimeout(async () => {
          if (this.isValid) {
            this.callbackFn = editSuccessCallback
            await this.onUpdateConfirm()
          }
        }, 150)
      }
      this.$bvModal.show(this.dialogId)
    },

    showAddDialog(createSuccessCallback) {
      this.formMode = FORM_MODE.ADD
      this.dialogTitle = this.dialogTitles.ADD.text
      this.formData = null
      this.buttonText = '保存'
      this.onAddOrEditConfirm = async () => {
        this.triggerValidate = !this.triggerValidate
        setTimeout(async () => {
          if (this.isValid) {
            const formData = pick(this.formData, this.requiredCreateKeys)
            const { success, data } = await packageInformationService.createItem(formData)
            if (!success) {
              this.displayAlert({
                type: ALERT_TYPE.ERROR,
                messages: data.name ? MESSAGES.ADMIN_MT.ADM06 : MESSAGES.COMMON.MSG15,
              })
              return
            }
            createSuccessCallback && createSuccessCallback()
            this.fetchCommonSetting()
            this.$bvModal.hide(this.dialogId)
            this.displayAlert({
              type: ALERT_TYPE.SUCCESS,
              messages: MESSAGES.COMMON.MSG01,
            })
          }
        }, 150)
      }
      this.$bvModal.show(this.dialogId)
    },

    async onUpdateConfirm() {
      const formData = pick(this.formData, this.requiredEditKeys)
      const { success, data } = await packageInformationService.editItem(formData)
      if (!success) {
        this.displayAlert({
          type: ALERT_TYPE.ERROR,
          messages: data.name ? MESSAGES.ADMIN_MT.ADM06 : MESSAGES.COMMON.MSG15,
        })
        return
      }
      this.callbackFn && this.callbackFn()
      this.fetchCommonSetting()
      this.$bvModal.hide(this.dialogId)
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.COMMON.MSG02,
      })
    },
  },
}
</script>
