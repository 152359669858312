<template>
  <div class="Table PackageInformation">
    <!--  table toolbar  -->
    <div class="Table__toolbar">
      <div class="Table__toolbar-search">
        <search-input v-if="hasSearch" :value.sync="searchInput" />
        <slot name="left" />
      </div>
      <div class="Table__toolbar-buttons">
        <slot name="right" />
        <add-new-button v-if="hasAddButton" class-name="add-button" :func="onCreate" />
      </div>
    </div>
    <!--    table content  -->
    <div class="table-responsive">
      <b-table
        ref="cm-table"
        :items="items"
        :fields="fields"
        sort-by="code"
        thead-class="Table__header"
        tbody-tr-class="Table__row"
        hover
      >
        <template #head()="data">
          <div v-if="!!data.label" class="header">
            <div class="header--value">
              {{ data.label }}
            </div>
            <div v-if="data.field.sortable" class="header--sort">
              <SortIcon />
            </div>
          </div>
          <div v-else class="action">
            {{ '' }}
          </div>
        </template>

        <template #cell(price)="data">
          <div>{{ data.value }}円</div>
        </template>

        <template #cell(actions)="data">
          <div class="cell cell_action">
            <img
              src="@/assets/icons/ic_edit.svg"
              alt="edit-icon"
              @click.stop.prevent="onEdit(data.item)"
            />
          </div>
        </template>
      </b-table>
    </div>
    <!--   table paging   -->
    <div class="Table__paging">
      <table-paging
        :current-page.sync="currentPage"
        :per-page.sync="perPage"
        :total="total"
        :options-paging="optionsPaging"
      />
    </div>
    <!--    dialog   -->
    <slot name="dialog" />
  </div>
</template>

<script>
import SearchInput from '@/components/commons/common-search-input'
import AddNewButton from '@/components/add-new-button'
import TablePaging from '@/components/commons/common-table-paging'
import SortIcon from '@/components/sort-icon'
import { packageInformationService } from '@/services/package-information-service'

export default {
  name: 'PackageInformationManagementTable',
  components: { SearchInput, AddNewButton, TablePaging, SortIcon },
  props: {
    hasSearch: {
      type: Boolean,
      default: true,
    },
    hasAddButton: {
      type: Boolean,
      default: true,
    },
    showEditDialog: {
      type: Function,
      default: () => {
        return {}
      },
    },
    showAddDialog: {
      type: Function,
      default: () => {
        return {}
      },
    },
    showDeleteDialog: {
      type: Function,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      searchInput: '',
      fields: packageInformationService.getFields(),
      items: [],
      currentPage: 1,
      perPage: 10,
      total: 0,
      optionsPaging: [10, 50, 100],
    }
  },
  computed: {},
  watch: {
    searchInput(newValue) {
      this.handleValueChange(newValue)
    },
    perPage() {
      this.handleValueChange(this.searchInput)
    },
    currentPage() {
      this.handleValueChange(this.searchInput)
    },
  },
  async created() {
    await this.reloadTable()
  },
  methods: {
    onCreate() {
      this.showAddDialog(() => this.reloadTable(true))
    },
    onEdit(rowData) {
      this.showEditDialog(rowData, () => this.reloadTable(true))
    },
    onDelete(id) {
      this.showDeleteDialog(id, () => this.reloadTable(true))
    },
    async reloadTable(isMaster = false) {
      // reload tables data
      this.searchInput = ''
      const { items, total } = await packageInformationService.getItems(
        this.perPage,
        this.currentPage,
        this.searchInput,
        isMaster
      )
      this.items = items
      this.total = total
    },
    async handleValueChange(keyword) {
      const { items, total } = await packageInformationService.getItems(
        this.perPage,
        this.currentPage,
        keyword
      )
      this.items = items
      this.total = total
    },
  },
}
</script>
