import axios from '@/configs/http/internal-api-axios'
import { extendMasterRead } from '@/utils/function-utils'

const baseUrl = '/api/v1/mt/packages'

async function getItems(perPage = 10, currentPage = 1, keyword = '', isMaster = false) {
  let url = `${baseUrl}?perPage=${perPage}&currentPage=${currentPage}&keyword=${keyword}`
  url = extendMasterRead(isMaster, url)
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return {
    items: response.data.items,
    total: response.data.total,
  }
}

async function deleteItem(id) {
  if (id !== '') {
    const url = `${baseUrl}/${id}`
    return axios.delete(url).then((res) => {
      return res.data.success
    })
  }
  return false
}

async function createItem(formData) {
  const response = await axios
    .post(baseUrl, formData)
    .then((response) => {
      return {
        success: true,
        data: response.data,
      }
    })
    .catch((error) => {
      return {
        success: false,
        data: error.response.data.message,
      }
    })
  return {
    success: response.success,
    data: response.data,
  }
}

async function editItem(formData) {
  const url = `${baseUrl}/${formData.id}`
  const response = await axios
    .patch(url, formData)
    .then((res) => {
      return {
        success: true,
        data: res.data,
      }
    })
    .catch((err) => {
      return {
        success: false,
        data: err.response.data.message,
      }
    })
  return {
    success: response.success,
    data: response.data,
  }
}

function getFields() {
  return [
    { key: 'code', label: 'プランID', sortable: true },
    { key: 'name', label: 'プラン名', sortable: true },
    { key: 'price', label: '月間費用 (円)', sortable: true },
    { key: 'count_users', label: 'SaaS連携上限数', sortable: true },
    { key: 'actions', label: '', sortable: false },
  ]
}

export const packageInformationService = {
  getFields,
  getItems,
  deleteItem,
  createItem,
  editItem,
}
