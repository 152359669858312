<template>
  <div class="PIForm">
    <b-form>
      <b-form-group id="input-group-1" label="プラン名" label-for="input-1" class="required">
        <b-input-group>
          <b-form-input
            id="input-1"
            :value="form.name"
            type="text"
            :class="status($v.form.name)"
            :formatter="formatter"
            @change="setFormValue('name', $event)"
          />
          <b-input-group-append v-if="!!errorMessages.form.name" class="icon-alert">
            <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
          </b-input-group-append>
        </b-input-group>
        <span v-if="!!errorMessages.form.name" class="label-error"
          >{{ errorMessages.form.name }}
        </span>
      </b-form-group>

      <b-form-group id="input-group-2" label="月間費用 (円)" label-for="input-2" class="required">
        <b-input-group>
          <b-form-input
            id="input-2"
            v-model="form.price"
            type="text"
            :class="status($v.form.price)"
            :formatter="formatNumberPrice"
            :disabled="viewOnly"
            @change="setFormValue('price', $event)"
          />
          <b-input-group-append v-if="!!errorMessages.form.price" class="icon-alert">
            <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
          </b-input-group-append>
        </b-input-group>
        <span v-if="!!errorMessages.form.price" class="label-error"
          >{{ errorMessages.form.price }}
        </span>
      </b-form-group>

      <b-form-group id="input-group-3" label="SaaS連携上限数" label-for="input-3" class="required">
        <b-input-group>
          <b-form-input
            id="input-3"
            v-model="form.count_users"
            type="text"
            :formatter="formatNumberUser"
            :disabled="viewOnly"
            :class="status($v.form.count_users)"
            @change="setFormValue('count_users', $event)"
          />
          <b-input-group-append v-if="!!errorMessages.form.count_users" class="icon-alert">
            <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
          </b-input-group-append>
        </b-input-group>
        <span v-if="!!errorMessages.form.count_users" class="label-error"
          >{{ errorMessages.form.count_users }}
        </span>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { MESSAGES } from '@/utils/messages'
import { stringToNumberInput } from '@/utils/function-utils'
import { MAX_LENGTH } from '@/utils/constants'

export default {
  name: 'PackageForm',
  props: {
    formData: {
      type: Object,
      default: null,
    },
    triggerValidate: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
    viewOnly: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      form: {},
      errorMessages: {
        form: {
          name: '',
          price: '',
          count_users: '',
        },
      },
    }
  },
  validations: {
    form: {
      name: {
        required,
      },
      price: {
        required,
      },
      count_users: {
        required,
      },
    },
  },
  computed: {
    defaultFormData() {
      return { id: '', name: '', price: '', count_users: '' }
    },
  },
  watch: {
    form(newValue) {
      this.$emit('update:formData', newValue)
    },
    triggerValidate(newVal) {
      this.$emit('update:isValid', this.isValidForm())
    },
  },
  mounted() {
    this.form = { ...this.defaultFormData }
    if (this.formData) {
      this.form = { ...this.form, ...this.formData }
    }
  },
  methods: {
    setValidateErrorMessage(instance, name) {
      // check required
      if (!instance.required) {
        this.errorMessages.form[name] = MESSAGES.COMMON.MSG08
      } else {
        this.errorMessages.form[name] = ''
      }
    },
    setFormValue(name, value) {
      if (value === undefined) {
        return
      }
      this.form[name] = value
      const validateInstance = this.$v.form[name]
      validateInstance.$touch()
      this.setValidateErrorMessage(validateInstance, name)
    },
    status(validation) {
      return {
        invalid: validation.$dirty && validation.$invalid,
      }
    },
    isValidForm() {
      this.$v.form.$touch()
      const isValid = !this.$v.form.$invalid
      if (!isValid) {
        Object.keys(this.errorMessages.form).forEach((key) => {
          this.setValidateErrorMessage(this.$v.form[key], key)
        })
      }
      return isValid
    },
    formatter(e) {
      return String(e).substring(0, MAX_LENGTH.DEFAULT)
    },
    formatNumberPrice(e) {
      const value = stringToNumberInput(e)
      return String(value).substring(0, MAX_LENGTH.PRICE)
    },
    formatNumberUser(e) {
      const value = stringToNumberInput(e)
      return String(value).substring(0, MAX_LENGTH.COUNT_USER)
    },
  },
}
</script>
